//BRAND
export const name = 'AI TEXT TO COURSE';
export const company = 'AI TEXT TO COURSE';
export const websiteURL = 'https://aitexttocourse.com';
export const serverURL = 'https://aicourse-r7ob.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/ai-text-to-couurse.appspot.com/o/WhatsApp%20Image%202024-10-01%20at%2015.39.06.jpeg?alt=media&token=8161ff04-8fb3-4511-b318-3abc3e90575d';
export const razorpayEnabled = false;
export const paypalEnabled = true;
export const stripeEnabled = false;
export const paystackEnabled = false;
export const flutterwaveEnabled = false;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 9;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 99;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The AI Course Generator revolutionized my content creation process, providing accurate and relevant topics effortlessly. It's a time-saving powerhouse that enhances the quality and relevance of my courses. A must-have tool for educators seeking efficiency and impactful online learning experiences.";
export const from = "Sherrie Arnold";
export const profession = 'AI TEXT TO COURSE';
export const photoURL = 'https://firebasestorage.googleapis.com/v0/b/ai-text-to-couurse.appspot.com/o/WhatsApp%20Image%202024-10-01%20at%2015.39.06.jpeg?alt=media&token=8161ff04-8fb3-4511-b318-3abc3e90575d';

//PAYPAL
export const paypalPlanIdOne = "P-22390895H1423423KM4AAKIY";
export const paypalPlanIdTwo = "P-79M76646PL656892EM4AANIA";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";

//STRIPE
export const stripePlanIdOne = "price_1OTo7CSDXmLtVnVeaHIHxqCj";
export const stripePlanIdTwo = "price_1OTo7eSDXmLtVnVeBbn82U5B";

//PAYSTACK
export const paystackPlanIdOne = "PLN_ouqmm8eo6i2k9k8";
export const paystackPlanIdTwo = "PLN_1v1xqb8io9t5lis";
export const amountInZarOne = '170';
export const amountInZarTwo = '1871';

//FLUTTERWAVE
export const flutterwavePlanIdOne = "67960";
export const flutterwavePlanIdTwo = "67961";
export const flutterwavePublicKey = "FLWPUBK_TEST-6ee1faf6460ea587f510a024ac4c2b23-X";

//SOCIAL SIGNIN
export const googleClientId = "150644331076-flt197jvuqg9ohlf6q9rsjk3e3qomjd2.apps.googleusercontent.com";
export const facebookClientId = "8065327730213158";